<template>
  <el-form :model="collectionForm" :rules="rules" ref="form" label-width="100px" class="collectionForm" size="small">
    <el-row>
      <el-col :span="12">
        <el-form-item label="回款时间" prop="collection_date">
          <el-date-picker
            type="date"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            v-model="collectionForm.collection_date"
            style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="回款金额" prop="collection_amount">
          <el-input
            type="number"
            step="0.01"
            v-model="collectionForm.collection_amount"
            onblur="value = isNaN(parseFloat(value))? 0 : parseFloat(value)"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="回款方式" prop="collection_way">
          <el-input v-model="collectionForm.collection_way"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="collectionForm.cailiao" placeholder="请选择材料名称">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="规格" prop="cailiaoguige">
          <el-select v-model="collectionForm.cailiaoguige" placeholder="请选择材料规格">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="9">
        <el-form-item label="单号" prop="danhao">
          <el-input v-model="collectionForm.danhao"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="合同内数量" prop="htnamount">
          <el-input v-model.number="collectionForm.htnamount"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="增量数量" prop="zlamount">
          <el-input v-model.number="collectionForm.zlamount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="租金" prop="zujin">
          <el-input v-model="collectionForm.zujin"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="押金" prop="yajin">
          <el-input v-model="collectionForm.yajin"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="吊装费" prop="diaozhuangfei">
          <el-input v-model="collectionForm.diaozhuangfei"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="运费" prop="yunfei">
          <el-input v-model="collectionForm.yunfei"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="3" v-model="collectionForm.remark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    collectionForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      cailiaos_options: [],
      cailiaoguiges_options: [],

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        collection_date: [{ required: true, message: '请选择回款时间', trigger: 'blur' }],
        // collection_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        collection_way: [{ required: true, message: '请输入回款方式', trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    initForm() {
      this.getCailiaos(), this.getCailiaoguiges()
    }
  },
  created() {
    this.initForm()
  }
}
</script>

<style lang="scss" scoped></style>
